import * as React from 'react';

import VideoStyled from './styles/Video.styled';
import mobileCheck from '../utils/mobileDetector';

type Props = {
  poster: boolean,
  clickHandler: () => void,
};

const Video = ({ poster, clickHandler }: Props): JSX.Element => {
  return (
    <VideoStyled
      className={`${poster ? `isOn` : `isOff`} ${
        mobileCheck() ? `isMobile` : `isDesktop`
      }`}
    >
      <div className="intro-wrapper">
        <div className="intro">
          <p className="title">
            Kancelaria Adwokacka <br />
            Elwira Bębenek
          </p>
        </div>
      </div>
      <div className="next-btn" onClick={() => clickHandler()}>
        <div className="next-icon">
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 30 30"
              className="scaling-svg"
              width="30"
            >
              <path d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm3.86,17.65-5.79,5.8-2.65-2.66L16.21,15,10.42,9.21l2.65-2.66,5.79,5.8L21.52,15Z" />
            </svg>
          </div>
        </div>
        <div className="next-txt">
          <p>Poznaj szczegóły</p>
        </div>
      </div>
      <video autoPlay muted loop>
        <source src="../../tz_video.mp4" type="video/mp4" />
      </video>
    </VideoStyled>
  );
};

export default Video;
