import styled from "styled-components";

const Video = styled.div`
  position: relative;
  &.isOn {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    }
    .next-btn {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      display: flex;
      padding: 1rem;
      background: rgba(156, 183, 213, 0.3);
      border-radius: 1rem;
      border: 1px solid #6798d4;
      justify-content: center;
      z-index: 1000;
      cursor: pointer;
      &:hover {
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid #fff;
        .next-icon {
          svg {
            fill: #fff;
          }
        }
        .next-txt {
          p {
            color: #fff;
          }
        }
      }
      .next-icon {
        margin-right: 0.5rem;
        .icon {
        }
        svg {
          fill: #1f3040;
        }
      }
      .next-txt {
        p {
          color: #1f3040;
          font-weight: bold;
          font-size: 1rem;
          line-height: 30px;
          margin-bottom: 0;
        }
      }
    }
  }
  .intro-wrapper {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .intro {
        text-align: center;
        .title {
          color: #fff;
          font-size: 6rem;
          font-weight: bold;
          line-height: 7rem;
        }
        p {
          margin-bottom: 0;
          color: #fff;
          font-size: 1.2rem;
          line-height: 1.4rem;
        }
      }
  }
  &.isOn {
    display: block;
  }
  &.isOff {
    display: flex;
    flex-direction: column-reverse;
    .next-btn {
      display: none;
    }
    .intro-wrapper {
      position: absolute;,
      .title {
        font-size: 4rem;
        line-height: 1;
        font-weight: bold;
        margin: 0.5rem 0;
        /* font-family: 'Times New Roman', Times, serif; */
      }
    }
    /* .intro-wrapper {
      text-align: center;
      .title {
        font-size: 1.5rem;
        font-weight: bold;
        margin: 1rem 0;
        font-family: 'Times New Roman', Times, serif;
      }
      small {
        p {
          padding: 0;
          margin: 0;
          line-height: 1rem;
        }
      }
    } */
  }

  &.isMobile {
    @media (max-width: 768px) {
      .intro{
        .title{
          font-size:1.5rem;
        }
      }
    }
  }

  video {
    width: 100%;
  }
`;

export default Video;
