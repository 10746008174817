import * as React from 'react';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Layout from '../components/Layout';
import Intro from '../components/styles/intro.styled';
import Home from '../components/styles/Home.styled';
import mobileCheck from '../utils/mobileDetector';

import Video from '../components/Video';
// markup
const IndexPage = (): JSX.Element => {
  const [poster, setPoster] = useState(true);
  const clickHandler = (): void => {
    const inFifteenMinutes = new Date(new Date().getTime() + 1 * 900 * 1000);
    Cookies.set('intro', true, {
      expires: inFifteenMinutes,
    });
    setPoster(false);
  };
  useEffect(() => {
    if (!mobileCheck()) {
      if (Cookies.get('intro')) {
        setPoster(false);
      } else {
        setPoster(true);
      }
    } else {
      setPoster(false);
    }
  });
  return (
    <Layout theme="">
      <Home>
        <Video poster={poster} clickHandler={clickHandler} />
        <article className="bio">
          <Intro>
            <p>
              <strong>Elwira Bębenek</strong>
            </p>
            <p>
              Absolwentka Wydziału Prawa i Administracji Uniwersytetu
              Gdańskiego, członkini Pomorskiej Izby Adwokackiej w Gdańsku.
            </p>
          </Intro>
        </article>
        <article>
          <Intro>
            <p>
              Kancelaria zajmuje się świadczeniem pomocy prawnej, obejmującej
              udzielanie porad prawnych, sporządzanie pism procesowych, opinii
              prawnych oraz umów, jak również reprezentację przed sądami
              powszechnymi, organami ścigania, a także organami administracji
              publicznej. Pomoc prawna świadczona jest na terenie całego kraju,
              zarówno na rzecz klientów indywidualnych, jak i przedsiębiorców.
            </p>
          </Intro>
        </article>
      </Home>
    </Layout>
  );
};

export default IndexPage;
